@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.row-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    color: #000;
}

.faq-row{
    border-bottom: none!important;
}

.row-title-text{
    padding-left: 50px;
}

.expanded .row-title-text  {
    color: #D00909!important;
}

.faq-row:has(.expanded){
    border-left : 3px solid #D00909;
}

.faq-row{
    border-left : 1px solid #DBDADA;
}

@layer components {
    .contact-form input.normal{
        @apply border-[0.1rem] border-gray-800 bg-[#F2F2F2] h-10 px-5 w-1/2 rounded-lg text-sm focus:outline-none;
    }
    .contact-form input.small{
        @apply border-[0.1rem] border-gray-800 bg-[#F2F2F2] h-10 px-5 w-1/3 rounded-lg text-sm focus:outline-none;
    }
    .contact-form input.big{
        @apply border-[0.1rem] border-gray-800 bg-[#F2F2F2] h-10 px-5 w-2/3 rounded-lg text-sm focus:outline-none;
    }
    .contact-form input.bigger{
        @apply border-[0.1rem] border-gray-800 bg-[#F2F2F2] h-10 px-5 w-full rounded-lg text-sm focus:outline-none;
    }
    .contact-form textarea{
        @apply border-[0.1rem] border-gray-800 bg-[#F2F2F2] pt-3 px-5 w-full rounded-lg text-sm focus:outline-none;
    }
}